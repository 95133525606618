import { format, parse } from 'date-fns'
import {
  LanguageId,
  EventFieldsFragment,
  EventsDocument,
  StickyEventDocument,
  RelatedEventsDocument,
  FieldParagraphStickyEventTeaserFieldEventTag,
  EventListDocument,
  EventTeasersDocument,
} from '../graphql/generated'
import { ComposerTranslation } from '@nuxtjs/i18n/dist/runtime/composables'
import { Link } from '~/types'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'

export interface EventTeaser {
  id: number
  date: Date
  title: string
  location: string
  link: Link
  time: string
  type: string
  category: string
  physical: boolean
}

interface ParamRequest {
  document: TypedDocumentNode<any, any>
  cacheKey: string
  createVariables: () => any
}

interface ParamFilter {
  options: any
  values: any
}
export const createTransformResponse =
  (t: ComposerTranslation) =>
  (data: EventFieldsFragment[]): EventTeaser[] => {
    return (
      data?.map((item) => {
        return {
          id: item.nid || 0,
          time: item.fieldTimeWeekday || '',
          date: parse(item.fieldStartDate?.date, "yyyy-MM-dd HH:mm:ss 'UTC'", new Date()),
          title: item.fieldShortTitle ?? (item.title || ''),
          location: item.fieldPhysical
            ? `${item.fieldAddress?.addressLine1}, ${item.fieldAddress?.postalCode} ${item.fieldAddress?.locality}`
            : t('event.online'),
          link: {
            url: item.entityUrl?.path || '',
            target: '_self',
          },
          type: item.fieldEventType?.entity?.tid?.toString() || '',
          category: item.category?.entity?.tid?.toString() || '',
          physical: item.fieldPhysical || false,
        }
      }) || []
    )
  }

const createGetItems =
  (query: any, getVariables: any, transform: any) =>
  async (
    document: any = EventsDocument,
    cacheKey = 'events',
  ): Promise<{
    items: EventTeaser[]
    total: number
  }> => {
    const { data } = await useAsyncData(cacheKey, async () => {
      const { data } = await query({
        query: document,
        variables: getVariables(),
      })

      return {
        items: transform(data.nodeQuery?.entities as EventFieldsFragment[]),
        total: data.nodeQuery?.count || 0,
      }
    })

    return data.value ?? { items: [], total: 0 }
  }

export const useEvents = async (filter: ParamFilter, request: ParamRequest) => {
  const { languageCODE } = useLocale()
  const { t } = useI18n()
  const { clients } = useApollo()

  const perPage = 8
  const page = ref(1)
  const data = ref({
    items: [] as EventTeaser[],
    total: 0,
  })
  const items = computed(() => data.value.items)
  const pages = computed(() => Math.ceil(data.value.total / perPage))

  const getVariables = () => ({
    ...request.createVariables(),
    after: format(new Date(), 'yyyy-MM-dd'),
    offset: perPage * (page.value - 1),
    perPage,
    language: languageCODE.value as LanguageId,
  })
  const transform = createTransformResponse(t)
  const getItems = createGetItems(clients!.default.query, getVariables, transform)

  data.value = await getItems(request.document, request.cacheKey)

  watch([() => ({ ...filter.values }), page], async ([currentFilter], [previousFilter]) => {
    if (
      (currentFilter.type !== previousFilter.type || currentFilter.location !== previousFilter.location) &&
      page.value !== 1
    )
      return (page.value = 1) // triggers a new watch callback

    data.value = await getItems(request.document, request.cacheKey)
  })

  const years = computed(() =>
    items.value
      .map((event) => {
        return {
          event,
          year: event.date.getFullYear(),
        }
      })
      .reduce((years: Record<number, EventTeaser[]>, item) => {
        if (years[item.year] === undefined) {
          years[item.year] = [] as EventTeaser[]
        }
        years[item.year] = [...years[item.year], item.event]
        return years
      }, {}),
  )
  const noResults = computed(() => items.value.length === 0)
  return { years, page, pages, noResults } as const
}

export const useEventListing = async (
  filter: { discriminator: string; type: string[]; location: string[] },
  taxonomies: { eventTypes: string[]; eventCategories: string[] },
) => {
  const { languageCODE } = useLocale()
  const { t } = useI18n()
  const { clients } = useApollo()

  const getVariables = () => ({
    after: format(new Date(), 'yyyy-MM-dd'),
    language: languageCODE.value as LanguageId,
  })
  const transform = createTransformResponse(t)
  const getItems = createGetItems(clients!.default.query, getVariables, transform)

  const data = await getItems(EventListDocument, 'events-listing')

  const setQueryParams = async () => {
    const params = {
      ...(filter.type.length ? { type: filter.type.join(',') } : {}),
      ...(filter.location.length ? { physical: filter.location } : {}),
      scroll: 'false',
    }

    await navigateTo({ query: params, replace: true })
  }

  const allUsedTypeIds = [...new Set(data.items.map((item) => item[filter.discriminator as keyof EventTeaser]))]

  const perPage = 8
  const page = ref(1)

  const filteredItems = computed(() =>
    data.items
      .filter((item) => {
        if (filter.type.length === 0) return true
        return filter.type.length === 0 || filter.type.includes(item.type) || filter.type.includes(item.category)
      })
      .filter((item) => {
        if (filter.location.length === 0) return true
        return filter.location.includes(item.physical ? '1' : '0')
      }),
  )
  const pages = computed(() => Math.ceil(filteredItems.value.length / perPage))
  const items = computed(() =>
    filteredItems.value.filter((_, index) => index >= perPage * (page.value - 1) && index < perPage * page.value),
  )
  const years = computed(() =>
    items.value
      .map((event) => {
        return {
          event,
          year: event.date.getFullYear(),
        }
      })
      .reduce((years: Record<number, EventTeaser[]>, item) => {
        if (years[item.year] === undefined) {
          years[item.year] = [] as EventTeaser[]
        }
        years[item.year] = [...years[item.year], item.event]
        return years
      }, {}),
  )
  const noResults = computed(() => items.value.length === 0)

  watch([() => ({ ...filter }), page], async ([currentFilter], [previousFilter]) => {
    if (
      (currentFilter.type !== previousFilter.type || currentFilter.location !== previousFilter.location) &&
      page.value !== 1
    )
      return (page.value = 1) // triggers a new watch callback

    await setQueryParams()
  })

  return { years, page, pages, noResults, allUsedTypeIds } as const
}

export const useEventTeasers = async (
  eventTypes: string[],
  eventCategories: string[],
  count: number = 3,
  currentEventId: string = '0',
) => {
  const { languageCODE } = useLocale()
  const { t } = useI18n()
  const { clients } = useApollo()

  const transformResponse = createTransformResponse(t)

  const getVariables = () => ({
    after: format(new Date(), 'yyyy-MM-dd'),
    currentId: currentEventId,
    count,
    eventTypes,
    eventCategories,
    language: languageCODE.value as LanguageId,
  })

  const { data } = await useAsyncData(`event-teasers`, async () => {
    const { data } = await clients!.default.query({
      query: EventTeasersDocument,
      variables: getVariables(),
    })
    if (!data) return { items: [] }

    return {
      items: transformResponse(data.nodeQuery?.entities as EventFieldsFragment[]),
    }
  })

  const items = computed(() => data.value?.items)

  return { items } as const
}

export const useEventsRelated = async ({ institutionId = ['0'], currentEventId = ['0'] }) => {
  const { languageCODE } = useLocale()
  const { t } = useI18n()
  const { clients } = useApollo()

  const transformResponse = createTransformResponse(t)

  const getVariables = () => ({
    institutionId,
    currentId: currentEventId,
    after: format(new Date(), 'yyyy-MM-dd'),
    language: languageCODE.value as LanguageId,
  })

  const { data } = await useAsyncData(`events-related`, async () => {
    const { data } = await clients!.default.query({
      query: RelatedEventsDocument,
      variables: getVariables(),
    })
    if (!data) return { items: [] }

    return {
      items: transformResponse(data.nodeQuery?.entities as EventFieldsFragment[]),
    }
  })

  const items = computed(() => data.value?.items)

  return { items } as const
}

export const useStickyEvent = async (entity: FieldParagraphStickyEventTeaserFieldEventTag | null) => {
  const { languageCODE } = useLocale()
  const { t } = useI18n()
  const { clients } = useApollo()
  if (!entity)
    return {
      items: [],
    }

  const transformResponse = createTransformResponse(t)
  const selectedEventTag = entity ? [`${entity.entity?.tid}`] : ''

  const getVariables = () => ({
    after: '',
    currentId: '0',
    count: 1,
    eventTag: selectedEventTag ? selectedEventTag : '',
    language: languageCODE.value as LanguageId,
  })

  const { data } = await useAsyncData(`sticky-event`, async () => {
    const { data } = await clients!.default.query({
      query: StickyEventDocument,
      variables: getVariables(),
    })
    if (!data) return { items: [] }

    return {
      items: transformResponse(data.nodeQuery?.entities as EventFieldsFragment[]),
    }
  })

  const eventItem = computed(() => data.value?.items[0] as EventTeaser)

  return { eventItem } as const
}
